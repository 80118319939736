<template>
  <div class="bgBlue p-4 d-flex flex-column align-items-center">
    <h1 class="fontAsap my-2 colorDarkBlue">Luís Gomes</h1>
    <img class="my-4" src="../assets/icon.png" width="18%" />
    <h1 class="fontBarlow my-4 colorWhite mt-5">Page not found</h1>
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style scoped>
div {
  height: 100vh;
}
</style>
